<template>
	<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M10.4995 1.5L1.49951 10.5M1.49951 1.5L10.4995 10.5" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
	name: 'close',
};
</script>

<style scoped></style>
