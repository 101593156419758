<template>
	<svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
		<path d="M12.7495 9L5.24951 9M5.24951 9L8.24951 6M5.24951 9L8.24951 12" stroke-linecap="round"
			stroke-linejoin="round" stroke-width="1.5"/>
	</svg>
</template>

<script>
export default {
	name: 'arrowLeft',
};
</script>

<style scoped></style>
