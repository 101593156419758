import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client/core';
import { createUploadLink } from 'apollo-upload-client';
import { createApolloProvider } from '@vue/apollo-option';

const uploadLink = createUploadLink({
	uri: process.env.VUE_APP_SERVER_URL
		? process.env.VUE_APP_SERVER_URL + '/v2/graphql/'
		: 'https://marketing-desk-test.ddns.net/backend/v2/graphql/',
	credentials: 'include',
});

// Middleware для ожидания готовности переменных
const waitForVariablesMiddleware = new ApolloLink((operation, forward) => {
	const variables = operation.variables;

	// Если переменные пусты, добавляем пустой объект
	if (!variables || Object.keys(variables).length === 0) {
		operation.variables = {}; // Устанавливаем пустой объект
	}

	return forward(operation); // Пропускаем запрос дальше
});
// Настраиваем Apollo Client с middleware и upload link
const apolloClient = new ApolloClient({
	link: ApolloLink.from([waitForVariablesMiddleware, uploadLink]),
	cache: new InMemoryCache(),
});

const apolloProvider = createApolloProvider({
	defaultClient: apolloClient,
});

export const baseApiUrl = process.env.VUE_APP_SERVER_URL;
export default apolloProvider;
