<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="18" height="18" rx="9" fill="#E0F2EE"/>
		<path d="M6.08301 9.00008L8.16634 11.0834L12.333 6.91675" stroke="#00916E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
	name: 'approved',
};
</script>

<style scoped></style>
