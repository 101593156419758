<template>
	<v-app style="background-color: #F4F4F6;">
		<v-app-bar
			elevation="0"
			height="48px"
			class="bb-grey position-relative"
			style="background-color: #F4F4F6; transition: transform 0.3s;"
			:style="{ transform: isHeaderVisible ? 'translateY(0)' : 'translateY(-100%)' }">
			<app-bar
				app
				:drawer="drawer"
				:me="me"
				:is-lost-connection="isLostConnection"
				:sidebar-mini="sidebarMini"
				@show-sidebar="showSidebar" />
		</v-app-bar>
  
		<v-navigation-drawer
			v-if="!isLostConnection"
			v-model="drawer"
			temporary
			width="240"
			color="#F4F4F6"
			class="border-none"
			:style="{
				top: isHeaderVisible ? '48px' : '0',
				height: isHeaderVisible ? 'calc(100vh - 48px)' : '100vh',
				zIndex: $vuetify.display.smAndDown ? '1500' : '5'
			}"
			:permanent="$vuetify.display.mdAndUp">
			<SideBar
				ref="sidebar"
				v-model:welcomeShow="welcomeShow"
				:sidebar-mini="sidebarMini && false"
				:my-birthday="myBirthday"
				:birthday="birthday"
				:me="me"
				@check-birthday="checkBirthday"
				@show-sidebar="showSidebar"
				@add-snack="addSnack" />
		</v-navigation-drawer>
		<v-main>
			<v-container
				fluid
				style="height: 100vh"
				class="scroll pa-0 bg-neutral-5"
				:style="{marginTop: isHeaderVisible ? '0' : '-48px'}"
				@scroll="handleScroll">
				<router-view />
			</v-container>
		</v-main>
		<MdSnackbars v-model:messages="messages" style="z-index: 10 !important" />
	</v-app>
</template>
<script>
import formatDate from '@/helpers/formatDate';
import AppBar from '@/components/AppBar';
import SideBar from '@/components/SideBar';
import gql from 'graphql-tag';
import _ from 'lodash';
import MdSnackbars from '@/components/md-ui/snackbars/MdSnackbars';
export default {
	name: 'MainLayout',
	components: {MdSnackbars, AppBar, SideBar},
	data() {
		return {
			isHeaderVisible: true,
			lastScrollTop: 0,
			flagUpdateSnack: false,
			isLostConnection: false,
			appAccess: false,
			isFirstMount: true,
			messages: this.$store.state.messages,
			updateKey: 'F_MD_28_03',
			drawer: true,
			sidebarMini: false,
			birthday: [],
			birthdayShow: false,
			myBirthday: [],
			myBirthdayShow: false,
			welcomeShow: false,
			updateSnack: false,
			mdUpdate: null,
			closeFlag: true,
		};
	},
	computed: {
		christmasMode() {
			return this.$store.getters.getChristmasMode;
		},
		me() {
			return this.$store.getters.getMe || {};
		},
		redirectRegion() {
			return this.$store.getters.getRedirectRegions;
		},
		redirectProject() {
			return this.$store.getters.getRedirectProjects;
		},
	},
	watch: {
		redirectProject: {
			handler() {
				this.getRedirectProject();
			},
			deep: true,
		},
	},
	created() {
		if (document.cookie.includes('Login=true')) {
			this.ping();
		} else {
			document.cookie = 'Login=false';
			this.$router.push('/login');
		}
	},
	mounted() {
		this.$store.commit('updateOrderTypes');
	},
	methods: {
		formatDate,
		showSidebar() {
			this.$store.commit('updateDrawer');
			setTimeout(() => {
				this.drawer = this.$store.state.drawer;
				this.sidebarMini = !this.sidebarMini;
			});
		},
		checkBirthday() {
			this.welcomeShow = false;
			localStorage.setItem('welcomeShowU', this.formatDate(new Date()));
			if (this.birthday.length && this.me.length) {
				const myBth = this.birthday.indexOf(this.birthday.find((el) => el.id === this.me.id));
				this.myBirthday = myBth >= 0 ? this.birthday.splice(myBth, 1) : [];
			}
			const showBirth = localStorage.getItem('showBirth');
			if (
				(this.myBirthday.length || this.birthday.length) &&
        (!showBirth || (showBirth && showBirth !== this.formatDate(new Date())))
			) {
				this.$refs.sidebar.showBirth();
				localStorage.setItem('showBirth', this.formatDate(new Date()));
			}
		},
		handleScroll(event) {
			const currentScrollTop = event.target.scrollTop;

			window.requestAnimationFrame(() => {
				// Если контейнер в самом верху — показываем хедер
				if (currentScrollTop === 0) {
					this.isHeaderVisible = true;
				} 
				// Если скроллим вниз — скрываем хедер
				else if (currentScrollTop > this.lastScrollTop) {
					this.isHeaderVisible = false;
				}
				// Если скроллим вверх, ничего не делаем
				this.lastScrollTop = Math.max(0, currentScrollTop);
			});
		},

		addSnack(icon, result, text) {
			this.$store.commit('addMessage', { icon, result, text });
		},
		async getBirthday() {
			await this.$apollo
				.query({
					query: gql`
            query getBirthday {
              insights {
                team {
                  birthdayToday {
                    fullName
                    id
                    birthday
                    initials
                    telegram
                    team {
                      name
                      defaultColor
                    }
                  }
                }
              }
            }
          `,
					fetchPolicy: 'no-cache',
				})
				.then((data) => {
					this.birthday = data.data.insights.team.birthdayToday;
				})
				.catch((err) => {
					this.addSnack('Error', 'Error', err.message);
				});
		},
		async getMe() {
			await this.$apollo
				.query({
					query: gql`
            query getMe {
              auth {
                me {
                  tabPermissions {
                    readAffilateMarketing
                    writeAffilateMarketing
                    readAwsCredentials
                    readTechnical
                    readOverview
                    useBrief
                    useToolsProfiler
                    useToolsMonetization
                    useToolsSpamChecker
                    useToolsReuseChecker
                    readLinkPbn
                    useToolsKeywordsChecker
                    useToolsTopPages
                    useToolsCrossmap
                    readFinance
                    readFinanceTasks
                    readFinanceBalance
                    readFinanceCards
                    readFinancePl
                    readFinanceUnitEconomics
                    readLinkbuilding
                    readLinkbuildingOutreachBase
                    readLinkbuildingItem
                    readLinkbuildingAccount
                    writeBackupsCreating
                    readMaintenanceProjects
                    readContentMarketing
                    readContentMarketingContent
                    readContentMarketingCopywriters
                    readContentMarketingCopywritersTab
                    readSemantics
                    readFinanceOrders
                    readSuppliers
                    readMaintenanceTools
                    readMaintenance
                    readMaintenanceGscConnections
                    readMaintenanceEmails
                    readMaintenanceHostingsRegistrators
                    readMaintenanceGenerateDeveloperToken
                    readCloudFlareApi
                    readCloudFlareMail
                    readCloudFlarePassword
                    readMaintenanceProjectManagement
                    readMaintenanceProxies
                    readReports
                    readSourceGraphic
                    readTeam
                    readToolsHeatmap
                    useInsights
                    readAnotherProjects
                    readCmsInfo
                    readMailInfo
                    readGscMailInfo
                    readRecoveryMailInfo
                    readRegistratorInfo
                    readControlPanelInfo
                    readWpApiInfo
                    writeAwsCredentials
                    writeCmsInfo
                    writeMailInfo
                    writeRecoveryMailInfo
                    writeGscMailInfo
                    writeRegistratorInfo
                    writeControlPanelInfo
                    writeWpApiInfo
                    writeMaintenanceHostingsRegistrators
                    writeMaintenanceGenerateDeveloperToken
                    writeCloudFlareApi
                    writeCloudFlareMail
                    writeCloudFlarePassword

                    # [ Reports ]
                    readReportsPageDetailInfo
                    writeReportsPageDetailInfo
                    readReportsCmsClicks
                    writeReportsCmsClicks
                    readReportsBhEffectivity
                    writeReportsBhEffectivity
                    readReportsRlTop
                    writeReportsRlTop
                    readReportsConversions
                    writeReportsConversions
                    readReportsNewKeywords
                    writeReportsNewKeywords
                    readPassportReport
                    writePassportReport
                    writeReportsUsersActivity
                    writeReportUserPerformance
                    writeReportsPbnPerformance
                    writeReportsContentProfiler
                    writeReportsTier
                    writeReportsSummaryStatisticBacklinks
                    writeReportsSprintTemplate
                    writeReportsProjectEconomics
                    writeReportsProjectBacklinks
                    writeReportsProfiler
                    writeReportsGaAffilate
                    writeReportsDeletedBacklinks
                    writeReportsCrossMap
                    writeReportsContentAudit
                    writeReportsBudgets
                    readReports
                    readReportsUsersActivity
                    readReportUserPerformance
                    readReportsPbnPerformance
                    readReportsContentProfiler
                    readReportsBudgets
                    readReportsContentAudit
                    readReportsCrossMap
                    readReportsDeletedBacklinks
                    readReportsGaAffilate
                    readReportsProfiler
                    readReportsProjectBacklinks
                    readReportsProjectEconomics
                    readReportsSprintTemplate
                    readReportsSummaryStatisticBacklinks
                    readReportsTier

                    # [ Marketing Plan ]

                    readMarketingPlan
                    readPlanningContent
                    writePlanningContent
                    readPlanningLinkbuilding
                    writePlanningLinkbuilding
                    readPbnPlanning

                    # [ Content Gap ]

                    readToolsContentGap
                    writeToolsContentGap

                    # [ Linkbuilding Item Hat ]

                    readLinkbuildingItemHatType
                    writeLinkbuildingItemHatType

                    # [ Anchor Analyzer ]

                    readToolsAnchorAnalyzer
                    writeToolsAnchorAnalyzer

                    # [ Management ]

                    readManagement
                    readManagementPl
                    readManagementUnitEconomics
                    readFinanceRevenueCabs

                    # [ Order Categories ]

                    readFinanceOrdersContent
                    readFinanceOrdersCrowdLinks
                    readFinanceOrdersContentDeploy
                    readFinanceOrdersDesign
                    readFinanceOrdersDevelopment
                    readFinanceOrdersDocs
                    readFinanceOrdersDomain
                    readFinanceOrdersHosting
                    readFinanceOrdersImages
                    readFinanceOrdersInfrastructure
                    readFinanceOrdersTools
                    readFinanceOrdersOutreachLinks
                    readFinanceOrdersMediaMaterials
                    readFinanceOrdersSubmitLinks
                  }
                  teamRole {
                    id
                    name
                  }
                  telegram
                  phone
                  birthday
                  mainRole
                  id
                  avatar
                  initials
                  firstName
                  fullName
                  email
                  birthday
                  team {
                    name
                    id
                    avatar
                    defaultColor
                  }
                }
              }
            }
          `,
					fetchPolicy: 'no-cache',
				})
				.then((data) => {
					this.getBaseData();
					if (!_.isEqual(data.data.auth.me, JSON.parse(localStorage.getItem('userInfo')))) {
						localStorage.setItem('userInfo', JSON.stringify(data.data.auth.me));
						this.$store.commit('updateMe', data.data.auth.me);
					}
					if (
						this.$store?.getters?.getMe &&
            this.$store?.getters?.getMe?.tabPermissions &&
            this.$store?.getters?.getMe?.tabPermissions?.useInsights
					) {
						this.getBirthday();
					}
				})
				.then(() => {
					const localKey = localStorage.getItem('MD_UPDATE_KEY');
					const welcomeDate = localStorage.getItem('welcomeShowU');
					if (
						!welcomeDate ||
            (welcomeDate && welcomeDate !== this.formatDate(new Date())) ||
            localKey !== this.updateKey
					) {
						this.welcomeShow = true;
						localStorage.setItem('MD_UPDATE_KEY', this.updateKey);
					}
				})
				.catch(() => {
					this.$router.push('/login');
				});
		},
		async getBaseData() {
			await this.$apollo
				.query({
					query: gql`
            query getBaseData {
              dropdown {
                projectDomains {
                  id
                  key
                }
              }
            }
          `,
					fetchPolicy: 'no-cache',
				})
				.then((data) => {
					this.$store.commit(
						'updateAllProjects',
						data.data.dropdown.projectDomains.map((e) => {
							return {
								domain: e.key,
								id: e.id,
							};
						})
					);
					this.getBaseDataPbn();
				})
				.catch((err) => {
					this.addSnack('Error', 'Error', err);
					this.getBaseDataPbn();
				});
		},
		async getBaseDataPbn() {
			await this.$apollo
				.query({
					query: gql`
            query getBaseDataPbn {
              dropdown {
                projectDomains(siteTypeIds: ["1", "7"]) {
                  id
                  key
                }
              }
            }
          `,
					fetchPolicy: 'no-cache',
				})
				.then((data) => {
					this.$store.commit(
						'updateAllProjectsPbn',
						data.data.dropdown.projectDomains.map((e) => {
							return {
								domain: e.key,
								id: e.id,
							};
						})
					);
					this.getBaseDataTech();
				})
				.catch((err) => {
					this.addSnack('Error', 'Error', err);
					this.getBaseDataTech();
				});
		},
		async getBaseDataTech() {
			await this.$apollo
				.query({
					query: gql`
            query getBaseDataTech {
              dropdown {
                projectDomains(withTechnical: true) {
                  id
                  key
                }
              }
            }
          `,
					fetchPolicy: 'no-cache',
				})
				.then((data) => {
					this.$store.commit(
						'updateAllProjectsTech',
						data.data.dropdown.projectDomains.map((e) => {
							return {
								domain: e.key,
								id: e.id,
							};
						})
					);
					this.getBaseDataNext();
				})
				.catch((err) => {
					this.addSnack('Error', 'Error', err);
					this.getBaseDataNext();
				});
		},
		async getBaseDataNext() {
			await this.$apollo
				.query({
					query: gql`
            query getBaseDataNext {
              athena {
                users {
                  id
                  gender
                  fullName
                  avatar
                  initials
                  team {
                    id
                    name
                    avatar
                    defaultColor
                  }
                }
              }
            }
          `,
					fetchPolicy: 'no-cache',
				})
				.then((data) => {
					this.$store.commit('updateAllUsers', data.data.athena.users);
					this.getBaseData1();
				})
				.catch((err) => {
					this.addSnack('Error', 'Error', err);
					this.getBaseData1();
				});
		},
		async getBaseData1() {
			await this.$apollo
				.query({
					query: gql`
            query getBaseData1 {
              athena {
                allRegions {
                  id
                  country {
                    code2
                    flag
                    id
                    name
                    code2
                  }
                  lang {
                    code2
                  }
                }
                allLanguages {
                  name
                  code2
                  mainCountry {
                    flag
                    name
                    id
                    flag
                  }
                  id
                }
                allCountries {
                  name
                  id
                  flag
                }
              }
            }
          `,
					fetchPolicy: 'no-cache',
				})
				.then((data) => {
					this.getBaseData2();
					this.$store.commit('updateAllRegions', data.data.athena.allRegions);
					this.$store.commit('updateAllLanguages', data.data.athena.allLanguages);
					this.$store.commit('updateAllCountries', data.data.athena.allCountries);
				})
				.catch((err) => {
					this.addSnack('Error', 'Error', err);
					this.getBaseData2();
				});
		},
		async getBaseData2() {
			await this.$apollo
				.query({
					query: gql`
            query getBaseData2 {
              athena {
                siteTypes {
                  id
                  name
                }
                currentStage {
                  id
                  name
                }
                projectStatuses {
                  id
                  name
                }
                domainTypes {
                  id
                  name
                }
                portalThemes {
                  name
                  id
                }
              }
            }
          `,
					fetchPolicy: 'no-cache',
				})
				.then((data) => {
					this.$store.commit('updateSiteTypes', data.data.athena.siteTypes);
					this.$store.commit('updateCurrentStage', data.data.athena.currentStage);
					this.$store.commit('updateProjectStatuses', data.data.athena.projectStatuses);
					this.$store.commit('updateDomainTypes', data.data.athena.domainTypes);
					this.$store.commit('updatePortalThemes', data.data.athena.portalThemes);
				})
				.catch((err) => {
					this.addSnack('Error', 'Error', err);
				});
		},
		async getRedirectProject() {
			this.$store.commit('updateLoadingRedirect', true);
			await this.$apollo
				.query({
					query: gql`
            query getRedirectProject($input: ProjectFilterInput = null) {
              maintenance {
                projects {
                  projects(input: $input) {
                    data {
                      redirectedFrom {
                        status {
                          id
                          name
                        }
                        domain
                        dateRedirected
                        region {
                          id
                        }
                      }
                      redirectProject {
                        status {
                          id
                          name
                        }
                        domain
                        dateRedirected
                      }
                    }
                  }
                }
              }
            }
          `,
					variables: {
						input: {
							projectsIds: this.redirectProject === undefined ? -1 : [this.redirectProject],
							regionIds: this.redirectRegion,
						},
					},
					fetchPolicy: 'no-cache',
				})
				.then((data) => {
					this.$store.commit('updateLoadingRedirect', false);
					this.$store.commit(
						'updateRedirectInfo',
						data.data.maintenance.projects.projects.data.length
							? data.data.maintenance.projects.projects.data
							: []
					);
				})
				.catch((err) => {
					this.$store.commit('updateLoadingRedirect', false);
					this.addSnack('Error', 'Error', err.message);
				});
		},
		async ping() {
			await this.$apollo
				?.query({
					query: gql`
            query ping {
              auth {
                ping
              }
              technical {
                notification {
                  getUpdateStatus
                }
              }
            }
          `,
					fetchPolicy: 'no-cache',
				})
				.then((data) => {
					this.closeFlag = true;
					this.mdUpdate = data.data.technical.notification.getUpdateStatus;
					if (this.mdUpdate && !this.updateSnack && this.flagUpdateSnack) {
						this.updateSnack = true;
						this.addSnack(
							'Warning',
							'Warning',
							'Marketing desk will be updated soon, please save your changes'
						);
					}
					this.flagUpdateSnack = true;
					if (this.isLostConnection) {
						this.isLostConnection = false;
						this.addSnack('Success', 'Connection restored', 'You can use marketing desk now!');
					}
					if (this.isFirstMount) {
						this.appAccess = true;
						this.checkBirthday();
						this.getMe();
						this.isFirstMount = false;
					}
					setTimeout(() => {
						this.ping();
					}, 5000);
				})
				.catch((err) => {
					const {networkError} = err;
					this.updateSnack = false;
					this.flagUpdateSnack = false;
					if (this.closeFlag) {
						this.messages.splice(0, 5);
						this.closeFlag = false;
					}
					if (this.$route.path !== '/login' && networkError) {
						if (networkError.statusCode === 401) {
							this.$router.push('/login');
							this.addSnack('Warning', 'Warning', 'You was unauthorized :( Try to log-in again');
						} else {
							if (!this.isLostConnection) {
								this.addSnack('Warning', 'Warning', 'Lost connection :( Trying to re-connect');
								if (this.mdUpdate) {
									setTimeout(() => {
										this.addSnack('Warning', 'Warning', 'Marketing desk is being updated, please wait');
									}, 1000);
								}
								this.isLostConnection = true;
							}
							setTimeout(() => {
								this.ping();
							}, 2000);
						}
					}
				});
		},
	},
};
</script>
<style lang="scss">
.today {
  background: #e7e7e7;
  transition: 0.2s;
  border-radius: 8px;
}
@media (max-width: 450px) {
  .v-navigation-drawer {
    top: 96px !important;
    height: calc(100vh - 96px) !important;
  }

  .v-container {
    height: calc(100vh - 96px) !important;
  }
}
</style>
