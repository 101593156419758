<template>
	<svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M2.70007 6.75H15.3001M2.70007 11.25H15.3001M8.62494 2.25C7.36144 4.27471 6.69159 6.6134 6.69159 9C6.69159 11.3866 7.36144 13.7253 8.62494 15.75M9.375 2.25C10.6385 4.27471 11.3083 6.6134 11.3083 9C11.3083 11.3866 10.6385 13.7253 9.375 15.75M2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 7.20979 15.0388 5.4929 13.773 4.22703C12.5071 2.96116 10.7902 2.25 9 2.25C7.20979 2.25 5.4929 2.96116 4.22703 4.22703C2.96116 5.4929 2.25 7.20979 2.25 9Z"
			stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
	</svg>
</template>

<script>
export default {
	name: 'global',
};
</script>

<style scoped></style>
