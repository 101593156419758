<template>
	<svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
		<path d="M5.24951 9L12.7495 9M12.7495 9L9.74951 6M12.7495 9L9.74951 12" stroke-linecap="round"
			stroke-linejoin="round" stroke-width="1.5"/>
	</svg>
</template>

<script>
export default {
	name: 'arrowRight',
};
</script>

<style scoped></style>
