<template>
	<svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.59993 8.80163C5.79993 9.06829 6.19993 9.06829 6.39993 8.80163L9.89993 4.13496C10.1471 3.80534 9.91195 3.33496 9.49993 3.33496H2.49993C2.08791 3.33496 1.85272 3.80534 2.09993 4.13496L5.59993 8.80163Z"
			fill="#D11149"/>
	</svg>
</template>
<script>
export default {
	name: 'moveDown',
};
</script>

<style scoped></style>
