export default function tableFrontSort(options, value, multisort) {
	if(multisort) {
		const sortObj = options?.sortBy?.find(item => item.key === value);
		if (sortObj) {
			return sortObj?.order === 'desc' ? 'custom-sort-desc' : 'custom-sort-asc';
		}
		return '';
	}
	else {
		if(options?.sortBy?.[0]?.key === value && options?.sortBy?.[0]?.order === 'desc'){
			return 'custom-sort-desc';
		}
		if(options?.sortBy?.[0]?.key === value && options?.sortBy?.[0]?.order === 'asc'){
			return 'custom-sort-asc';
		}
		else {
			return '';
		}
	}
}